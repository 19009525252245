import React, { useState, useEffect } from 'react'
import axios from 'axios';
import conf from '../../conf'

const Notification = ()=>{
     const [isSending, setIsSending] = useState(false);
     const [formData, setFormData] = useState({
          title: '',
          body: '',
          link: '',
          file: null,
          intervel: false,
          time: -1
     })

     const onSubmit = async ()=>{
          setIsSending(true)
          try {
               const rBody = new FormData();
               rBody.append('title', formData.title)
               rBody.append('message', formData.body)
               rBody.append('link', formData.link)
               rBody.append('file', formData.file)
               rBody.append('intervel', formData.intervel)
               rBody.append('time', formData.time)
               console.log(formData.file);
               const res = await axios.post(conf.serverAddress + "admin/push-notification", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               setFormData({
                    title: '',
                    body: '',
                    link: '',
                    file: null,
                    intervel: false,
                    time: -1
               })
               setIsSending(false);
               window.location.reload();
          } catch (error) {
               console.log("error");
               console.log(error);
               setIsSending(false)
               setFormData({
                    title: '',
                    body: '',
                    link: '',
                    file: null,
                    intervel: false,
                    time: -1
               })
          }
     }

     const onChangeForm = (event)=>{
          const { value, name } = event.target;
          setFormData(pVal => {
               if(name != 'file'){
                    return {
                         ...pVal,
                         [name]: value
                    };
               }
               else{
                    return {
                         ...pVal,
                         file: event.target.files[0]
                    };
               }
          })
     }

     const [isNoMoreUsers, setNoMoreUsers] = useState(false);
     const [page, setPageIndex] = useState(0);
     const [notifications, setNotifications] = useState([]);
     const [message, setMessage] = useState("Loading...");
     // const [search, setSearch] = useState("");

     const onPageChange = async (index, isNext, searchQuery)=>{
          try {
               setMessage('Loading...')
               const params = new URLSearchParams();
               params.append("skip", 20 * index);
               params.append("limit", 20);

               // if(searchQuery){
               //      params.append("searchQuery", searchQuery);
               // }

               const usersx = await axios.get(conf.serverAddress + "admin/notification-sadule", {params: params, headers : {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}});
               if(usersx.data.length != 0){
                    setNotifications(usersx.data);
                    setMessage('')
                    setNoMoreUsers(false)
                    if(isNext){
                         setPageIndex(index);
                    }
                    else{
                         setPageIndex(index);
                    }
               }    
               else{
                    setMessage('Empty!');
               } 
               if(usersx.data.length < 20){
                    setNoMoreUsers(true);
               }
          } 
          catch (error) {
               setMessage('Failed')
          }
     }

     // const onSearch = (query)=>{
     //      onPageChange(0, true, query);
     // }

     const buttonStyle = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px',
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)"};

     const buttonStyleDesable = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px', opacity: 0.2,
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)", };

     const vBox = {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
     };

     useEffect( async ()=>{ 
          onPageChange(0, true);
     }, []);

     return(
          <>
               <div style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: 'column'
          }}>
               <h3 style={{width: '500px', marginBottom: '20px'}}>Notification</h3>
               <textarea name='title' onChange={onChangeForm} style={{ width:'500px', border: 'none', padding: '10px 20px', borderRadius: '5px' }} 
                    placeholder="title..." 
                    maxLength={50}
                    value={formData.title}/>
               <input name='link' onChange={onChangeForm} style={{ width:'500px', border: 'none', padding: '10px 20px', borderRadius: '5px', marginTop: '15px' }} 
                    placeholder="link..." 
                    value={formData.link}/>
               <textarea name='body' onChange={onChangeForm} style={{ width:'500px', height:'100px', border: 'none', padding: '10px 20px', borderRadius: '5px', marginTop: '15px' }} 
                    placeholder="message..." 
                    maxLength={100}
                    value={formData.body}/>
               <h5 style={{width: '500px', marginTop: '10px'}}>Image</h5>
               <div style={{width: '500px'}}>
                    { formData.file ? <div  style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                              <img src={URL.createObjectURL(formData.file)} style={{maxWidth: '200px', height: '100px'}}/> 
                         </div> : 
                    <div style={{width: '200px', height: '100px', background: '#cdd3d4', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                         Image
                    </div> }
                    <input name='file' type='file' accept='image/*' onChange={(e)=>setFormData({
                         ...formData,
                         file: e.target.files[0]
                    })} style={{marginTop: '10px'}}/>
               </div>    

               <button onClick={onSubmit} className="badge badge-gradient-success"  style={{
                         width: '500px', display: 'flex', justifyContent: 'center',
                         border: 'none', borderRadius: '5px', margin: '15px 0', fontSize: '16px',
                         padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' 
                    }}>Send</button>
          </div>
          </>
     );
}
export default Notification;