import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import axios from "axios";
import conf from '../../conf'
import { Switch } from "@material-ui/core"
import {useToasts} from 'react-toast-notifications'

const TransactionItem = (props)=>{

     const {addToast} = useToasts();

     const go = useNavigate();
     const { _id,  name, mobileNumber, amount, transactionStatus, approvalRefNo, transactionId } = props.ele;
     const [visibal, setVisiblety] = useState(true);
     const [isSending, setSending] = useState(false);
     
     const statusStyleClass = transactionStatus === "SUCCESS" ? "badge badge-gradient-success" : "badge badge-gradient-danger";

     const onAddMoney = async ()=>{
          setSending(true)
          try{
               await axios.patch(conf.serverAddress + "admin/add-money-to-user", {id: _id}, {
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setVisiblety(false);
               setSending(false);
          }
          catch(err){
               addToast("Failed", {
                    appearance: 'error',
                    autoDismiss: true,
               })
               setSending(false);
          }
     }

     const onDelete = async ()=>{
          setSending(true)
          try{
               await axios.delete(conf.serverAddress + "admin/txns", {
                    data: {id: _id}, 
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setVisiblety(false);
               setSending(false);
          }
          catch(err){
               addToast("Failed", {
                    appearance: 'error',
                    autoDismiss: true,
               })
               setSending(false);
          }
     }

     if(!visibal) return null;

     return(
          <tr>
               <td>{props.id}</td>
               <td>{name}</td>
               <td>{mobileNumber}</td>
               <td style={{color: "#ebab34"}}>{approvalRefNo}</td>
               <td>{"₹" + amount}</td>
               <td><label className={ statusStyleClass }>{transactionStatus}</label></td>
               <td><label style={{cursor : isSending ? 'wait' : 'pointer'}} onClick={onAddMoney} className="badge badge-gradient-success">approve</label></td>
               <td><label style={{cursor : isSending ? 'wait' : 'pointer'}} onClick={onDelete} className="badge badge-gradient-danger">delete</label></td>
          </tr>
     );
}

export default TransactionItem;