import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import conf from '../../conf'
import {Switch} from '@mui/material'
import {ArrowRightRounded} from '@material-ui/icons'
import {useToasts} from 'react-toast-notifications'

const Setting = ()=>{

     const {addToast} = useToasts();
     const go = useNavigate();

     const defultValue = {
          adminPassword: "",
          whatsappNumber: "",
          upiId: "",
          youtubeLink: "",
          facebookLink: "",
          instagramLink: "",
          
          appDownloadLink: "",
          forceUpdate: false,
          versionCode: 1,
     };

     const [file, setfile] = useState(null);
     const [video, setvideo] = useState(null);
     const [isSending, setIsSending] = useState(false);
     const [formData, setFormData] = useState(null);

     const getSetting = async ()=>{
          try {
               const res = await axios.get(conf.serverAddress + "admin/setting", {
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setFormData(res.data)
          } catch (err) {
               console.log(err);
          }
     }

     useEffect(()=>{ getSetting(); }, [])

     const onSubmit = async ()=>{

          setIsSending(true)
          try {
               const rBody = new FormData();
               rBody.append('adminPassword', formData.adminPassword);
               rBody.append('upiId', formData.upiId);
               rBody.append('howToUseLink', formData.howToUseLink);
               rBody.append('whatsappNumber', formData.whatsappNumber);
               rBody.append('youtubeLink', formData.youtubeLink);
               rBody.append('facebookLink', formData.facebookLink);
               rBody.append('instagramLink', formData.instagramLink);

               rBody.append('appDownloadLink', formData.appDownloadLink);
               rBody.append('versionCode', formData.versionCode);
               rBody.append('forceUpdate', formData.forceUpdate);
               

               if(file){
                    rBody.append('file', file);
               }
               if(video){
                    rBody.append('helpVideo', video);
               }

               const res = await axios({
                    method: "PATCH", 
                    data: rBody,
                    url: conf.serverAddress + "admin/setting",
                    headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
                    }
               });
               setIsSending(false)
               addToast("Changes saved successfully", {
                    appearance: 'success',
                    autoDismiss: true,
               })
          } catch (error) {
               console.log(error);
               setIsSending(false)
               setFormData(defultValue)
               addToast("Failed", {
                    appearance: 'error',
                    autoDismiss: true,
               })
          }
     }

     const vBox = {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
     };

     const sInput = {width: "440px", marginTop: "10px", border: '1px solid #8a8a8a', borderRadius: "5px", padding: '10px 15px'};

     if(!formData) return null;

     return(
          <div style={{display: 'flex', justifyContent: "center"}}>
               <div className="card" style={{width: '500px', padding: "20px 25px"}}>
                    <h3>Setting</h3>
                    <div style={vBox}>
                         Force update <Switch checked={formData.forceUpdate} onChange={(e, is)=>setFormData({
                                   ...formData,
                                   forceUpdate: is
                              })} />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         App version code <br />
                         <input value={formData.versionCode} type="number" min={1} style={sInput} onChange={e=> setFormData({
                              ...formData,
                              versionCode: e.target.value
                         })} placeholder="Version code..." />
                    </div>
                    <div style={{marginTop: '15px'}}>
                         App download link <br />
                         <input value={formData.appDownloadLink} type="url" style={sInput} onChange={e=> setFormData({
                              ...formData,
                              appDownloadLink: e.target.value
                         })} placeholder="Link..." />
                    </div>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         Admin Password <br />
                              <input value={formData.adminPassword} type="text" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   adminPassword: e.target.value
                              })} placeholder="password..." />
                         </div>
                    </div>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         UPI ID <br />
                              <input value={formData.upiId} type="text" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   upiId: e.target.value
                              })} placeholder="id..." />
                         </div>
                    </div>
                    <div>
                         WhatsApp Number <br />
                         <input value={formData.whatsappNumber} type="number" maxLength={10} style={sInput} onChange={e=> setFormData({
                              ...formData,
                              whatsappNumber: e.target.value
                         })} placeholder="whatsapp number ..." />
                    </div>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         How to use ? <br />
                              <input value={formData.howToUseLink} type="url" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   howToUseLink: e.target.value
                              })} placeholder="Link..." />
                         </div>
                    </div>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         Facebook Link <br />
                              <input value={formData.facebookLink} type="url" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   facebookLink: e.target.value
                              })} placeholder="Link..." />
                         </div>
                    </div>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         Instagram Link <br />
                              <input value={formData.instagramLink} type="url" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   instagramLink: e.target.value
                              })} placeholder="Link..." />
                         </div>
                    </div>
                    <div>
                         <div style={{margin: '5px 0'}}>
                         Youtube Link <br />
                              <input value={formData.youtubeLink} type="url" style={sInput} onChange={e=> setFormData({
                                   ...formData,
                                   youtubeLink: e.target.value
                              })} placeholder="Link..." />
                         </div>
                    </div>
                    {/* <div style={{...vBox, margin: '10px 0', cursor:'pointer'}} onClick={()=>go("/admin-payment-setting")}>
                         Payment setting   <ArrowRightRounded/>
                    </div> */}
                    <button onClick={onSubmit} className="badge badge-gradient-success"  style={{
                         width: '440px', display: 'flex', justifyContent: 'center',
                         border: 'none', borderRadius: '5px', fontSize: '16px',
                         padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' ,
                         marginTop: '20px'
                    }}>Save</button>
               </div>
          </div>
     );
}
export default Setting;