import React from 'react';
import { useLocation } from "react-router-dom";
import conf from '../../conf';
import "./style.css";

const LandingPage = ()=>{

     const btn_text = (new URLSearchParams(useLocation().search)).get("update") ? "Download & Update" : "Download";

     async function onDownload(){
          window.location.assign(`${conf.serverAddress}download`)
     }

     return(
          <>
               <div className="top_bar">
                    <img className="brand_logo" src="./app_icon_round.png" alt="logo"/>
                    <h1 className="d-inline tc">Try luck online</h1>
               </div>
               <div style={{backgroundColor: "rgb(236, 236, 236)", height: "1px"}}></div>

               <div className="main_box">
                    <div className="main_box_div">
                         <h1 className="d-inline tc">Try luck online</h1>
                         <h1 className="tc">Download the app</h1>
                         <button onClick={onDownload} id="download_btn" className="download_btn">{btn_text}</button>
                    </div>
                    <div className="main_box_div">
                         <img className="add_demo" src="./img_app_demo.png" alt="demo"/>
                    </div>
               </div>
          </>
     );
}

export default LandingPage;