import React, {useState} from "react";
import {useToasts} from "react-toast-notifications";
import axios from "axios";
import conf from "../../conf";

const AddWinner = ()=> {

     const {addToast} = useToasts();
     const showToast = msg => addToast(msg, { appearance: 'error', autoDismiss: true });

     const date = new Date();
     const todaydate = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
     const [data , setData] = useState({
          priceType: '1st',
          winnerName: '',
          lotteryNumber: '',
          series: '',
          endDate: todaydate,
          type: 'Evening',
          lotteryPrices: '',
          winningPrices: '',
     });

     const onFormChange = (event) => {
          const value = event.target.value;
          const name = event.target.name;
          setData((prvObj)=>{
               return {
                    ...prvObj,
                    [name]: value
               };
          });
     }

     const onSave = async () => {

          const fromData = new FormData();
          
          for(let variable in data){
               fromData.append(variable, data[variable]);
          }
          
          try {
               await axios({
                    method: 'post',
                    url: conf.serverAddress + "admin/winner",
                    headers: {
                         "Content-Type": "Application/json",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
                    }, 
                    data: data
               });
               window.history.back();
          } 
          catch (err) {
               showToast("Failed to save.")
          }
     }

     const isFromValid = ()=>{
          if(!data.winnerName.trim()){
               showToast("Please enter winner name");
               return false;
          }
          else if(!data.lotteryNumber.trim()){
               showToast("Please enter lottery number");
               return false;
          }
          else if(!data.series.trim()){
               showToast("Please enter lottery series");
               return false;
          }
          else if(!data.endDate.trim()){
               showToast("Please enter winning date");
               return false;
          }
          else if(!data.lotteryPrices.trim()){
               showToast("Please enter lottery prices");
               return false;
          }
          else if(!data.winningPrices.trim()){
               showToast("Please enter winngin prize");
               return false;
          }
          return true;
     }

     const onAddWinnner = ()=>{
          if(isFromValid()){
               onSave();
          }
     }

     return(
          <div className="row">
               <div className="col-6 grid-margin">
                    <div className="card">
                         <div className="card-body">
                              <div>
                                   Rank
                                   <select value={data.priceType} name='priceType' onChange={onFormChange} style={{margin: "10px 100px"}}>   
                                        <option value="1st">1st</option>
                                        <option value="2nd">2nd</option>
                                        <option value="3rd">3rd</option>
                                        <option value="4th">4th</option>
                                        <option value="5th">5th</option>
                                   </select>
                              </div>
                              <div onChange={onFormChange}>
                                   Winner name 
                                   <input type="text" value={data.winnerName} name="winnerName" style={{margin: "10px 40px", border: '1px solid black'}}/>
                              </div>
                              <div onChange={onFormChange}>
                                   Lottery number
                                   <input type="text" value={data.lotteryNumber} name="lotteryNumber" style={{margin: "10px 21px", border: '1px solid black'}} />
                              </div>
                              <div onChange={onFormChange}>
                                   Series
                                   <input type="number" name="series" value={data.series} style={{margin: "10px 90px", border: '1px solid black'}} />
                              </div>
                              <div onChange={onFormChange}>
                                   Winning date
                                   <input type="text" name="endDate"  value={data.endDate} style={{margin: "10px 38px", border: '1px solid black'}} />
                              </div>
                              <div>
                                   Type
                                   <select value={data.type} name='type' onChange={onFormChange} style={{margin: "10px 100px"}}>   
                                        <option value="Evening">Evening</option>
                                        <option value="Day">Day</option>
                                        <option value="Morning">Morning</option>
                                        <option value="Bumper">Bumper</option>
                                   </select>
                              </div>
                              <div onChange={onFormChange}>
                                   Lottery prices
                                   <input value={data.lotteryPrices} type="text" name="lotteryPrices"  style={{margin: "10px 35px", border: '1px solid black'}} />
                              </div>
                              <div onChange={onFormChange}>
                                   Winning prize
                                   <input value={data.winningPrices} type="text" name="winningPrices"  style={{margin: "10px 40px", border: '1px solid black'}} />
                              </div>
                              
                              <button onClick={onAddWinnner} style={{color: "white", background: "blue", border: 'none', padding: "10px 20px", marginTop: "20px"}}>
                                   Save
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default AddWinner;