import axios from "axios";
import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import conf from '../../conf'

const UserDataItem = (props)=>{
     
     const actionEnable = props.actionEnable;
     const { userName,  email, balance, whatsappNumber, mobileNumber, upiId, password, _id, blocked, lastPaymentAt} = props.ele;
     const go = useNavigate();
     const [isLoading, setLoading] = useState(false);
     const [isBlocked, setBlocked] = useState(blocked ? true : false);
     const lastPaymentTime = (Date.now() - new Date(lastPaymentAt).getTime());
     const lp = lastPaymentTime < (1000*60*60)*24 ? "Today" : Number(lastPaymentTime/(1000*60*60*24)).toFixed() == 1 ? "Yesterday" : Number(lastPaymentTime/(1000*60*60*24)).toFixed() + " Days ago"

     const t = Math.floor(lastPaymentTime /(24*60*60*1000))

     const onSubmit = async (balance)=>{
          
          try {
               const rBody = new FormData();
               if(balance){
                    rBody.append('balance', balance);
               }
               rBody.append('id', _id);
               await axios.patch(conf.serverAddress + "admin/user", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               window.location.reload(false);
          } catch (error) {
               console.log(error);
          }
     }

     const onAddBalance = () => {
          const val = window.prompt(`Add money`,"10");;
          if(val && !isNaN(val)){
               onSubmit(val);
          }
     }

     const onBlock = async () => {
          try {
               setLoading(true)
               const rBody = new FormData();
               rBody.append('blocked', !isBlocked);
               rBody.append('userId', _id);
               await axios.patch(conf.serverAddress + "admin/user-block", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               setLoading(false);
               setBlocked(!isBlocked)
          } catch (error) {
               setLoading(false);
               console.log(error);
          }
     }
     
     return(
          <tr>
               <td>{props.id}</td>
               <td>
                   <img src="assets/images/faces/user.png" className="mr-2" alt="pic"/>
                   {actionEnable ?  <a className="ax" href={"admin-user-data?id="+_id}>{userName}</a> : userName}
               </td>
               <td>{ password }</td>
               <td>{ "₹" + balance }</td>
               <td>{  upiId }</td>
               <td>+91-{mobileNumber}</td>
               <td>+91-{whatsappNumber}</td>
               <td style={{fontWeight: 'bold', color: 'yellowgreen'}}>{lastPaymentAt ? lp : "None"}</td>
               <td>{email}</td>
               <td><label onClick={onAddBalance} className="badge badge-gradient-success" style={{cursor: 'pointer'}}>ADD MONEY</label></td>
               <td><label style={{cursor: isLoading ? 'wait' : 'pointer'}} onClick={onBlock} className={isBlocked ? "badge badge-gradient-success" : "badge badge-gradient-danger"}>{isBlocked ? "Unblock" : "Block"}</label></td>
          </tr>
     );
}

export default UserDataItem;