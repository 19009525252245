import React, {useState} from "react";
import {useToasts} from "react-toast-notifications";
import axios from "axios";
import conf from "../../conf";

const AddResult = ()=> {

     const {addToast} = useToasts();
     const showToast = msg => addToast(msg, { appearance: 'error', autoDismiss: true });

     const [isSending , setSending] = useState(false);
     const [file , setFile] = useState(null);
     const [data , setData] = useState({
          title: '',
     });

     const onFormChange = (event) => {
          const value = event.target.value;
          const name = event.target.name;
          setData((prvObj)=>{
               return {
                    ...prvObj,
                    [name]: value
               };
          });
     }

     const onSave = async () => {
          setSending(true);
          const fromData = new FormData();
          fromData.append("title", data.title);
          fromData.append("file", file);
          try {
               await axios({
                    method: 'post',
                    url: conf.serverAddress + "admin/result",
                    headers: {
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
                    }, 
                    data: fromData
               });
               window.history.back();
          } 
          catch (err) {
               setSending(false)
               showToast("Failed to save.")
          }
     }

     const isFromValid = ()=>{
          if(!data.title.trim()){
               showToast("Please enter title");
               return false;
          }
          else if(!file){
               showToast("Please choose image");
               return false;
          }
          return true;
     }

     const onAddWinnner = ()=>{
          if(isFromValid()){
               onSave();
          }
     }

     return(
          <div className="row">
               <div className="col-6 grid-margin">
                    <div className="card">
                         <div className="card-body">
                              <div onChange={onFormChange}>
                                   Title
                                   <input type="text" value={data.title} name="title" style={{margin: "10px 40px", border: '1px solid black', width: "300px"}}/>
                              </div>
                              <div onChange={onFormChange}>
                                   Image
                                   <input onChange={(e)=>setFile(e.target.files[0])} type="file" accept="image/*" style={{margin: "10px 35px", border: 'none'}} />
                              </div>
                              
                              <button onClick={onAddWinnner} style={{color: "white", background: "blue", border: 'none', padding: "10px 20px", marginTop: "20px", cursor: isSending ? 'wait' : "pointer"}}>
                                   Save
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default AddResult;