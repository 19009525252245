import React, {useState} from "react";
import {useToasts} from "react-toast-notifications";
import axios from "axios";
import conf from "../../conf";

const NewLottery = ()=> {

     const {addToast} = useToasts();
     const showToast = msg => addToast(msg, { appearance: 'error', autoDismiss: true });

     const date = new Date();
     const todaydate = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
     const [data , setData] = useState({
          series: 5,
          prices: 30,
          endDate: todaydate,
          type: 'Evening',
          time: '8 PM',
     });

     const onFormChange = (event) => {
          const value = event.target.value;
          const name = event.target.name;
          setData((prvObj)=>{
               return {
                    ...prvObj,
                    [name]: value
               };
          });
     }

     const onSave = async () => {

          const fromData = new FormData();
          
          for(let variable in data){
               fromData.append(variable, data[variable]);
          }
          
          try {
               await axios({
                    method: 'post',
                    url: conf.serverAddress + "admin/lottery",
                    headers: {
                         "Content-Type": "Application/json",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
                    }, 
                    data: data
               });
               window.history.back();
          } 
          catch (err) {
               showToast("Failed to save.")
          }
     }

     const isFromValid = ()=>{
          if(!data.series){
               showToast("Please enter lottery series");
               return false;
          }
          else if(!data.prices){
               showToast("Please enter lottery prices");
               return false;
          }
          else if(!data.endDate.trim()){
               showToast("Please enter winning date");
               return false;
          }
          else if(!data.type.trim()){
               showToast("Please enter lottery type");
               return false;
          }
          else if(!data.time.trim()){
               showToast("Please enter time");
               return false;
          }
          return true;
     }

     const onAddWinnner = ()=>{
          if(isFromValid()){
               onSave();
          }
     }

     return(
          <div className="row">
               <div className="col-6 grid-margin">
                    <div className="card">
                         <div className="card-body">
                              <div onChange={onFormChange}>
                                   Series
                                   <input type="number" value={data.series} name="series" style={{margin: "10px 85px", border: '1px solid black'}}/>
                              </div>
                              <div onChange={onFormChange}>
                                   Lottery prices
                                   <input type="number" value={data.prices} name="prices" style={{margin: "10px 30px", border: '1px solid black'}} />
                              </div>
                              <div onChange={onFormChange}>
                                   Winning date
                                   <input type="text" name="endDate"  value={data.endDate} style={{margin: "10px 38px", border: '1px solid black'}} />
                              </div>
                              <div>
                                   Type
                                   <select value={data.type} name='type' onChange={onFormChange} style={{margin: "10px 100px"}}>   
                                        <option value="Evening">Evening</option>
                                        <option value="Day">Day</option>
                                        <option value="Morning">Morning</option>
                                        <option value="Bumper">Bumper</option>
                                   </select>
                              </div>
                              <div onChange={onFormChange}>
                                   Series
                                   <input type="text" name="time" value={data.time} style={{margin: "10px 90px", border: '1px solid black'}} />
                              </div>
                              
                              <button onClick={onAddWinnner} style={{color: "white", background: "blue", border: 'none', padding: "10px 20px", marginTop: "20px"}}>
                                   Save
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default NewLottery;