import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import axios from "axios";
import conf from '../../conf'
import { Switch } from '@mui/material';

const LotteryItem = (props)=>{

     const go = useNavigate();
     const { series, open, prices, endDate, type, time, _id } = props.ele;
     const [isDelete, setDelete] = useState(false);
     const [isSending, setSending] = useState(false);
     const [isOpen, setOpen] = useState(open);

     console.log(props.ele);

     const onDelete = async () =>{
          setSending(true);
          try{
               await axios.delete(conf.serverAddress + "admin/lottery", {
                    data: {id: _id},
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setDelete(true);
          }
          catch(e){
               setSending(false);
          }
     }

     const onChange = async (checked) =>{
          setOpen(checked);
          try{
               await axios.patch(conf.serverAddress + "admin/lottery", {id: _id, open: checked}, {
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
          }
          catch(e){
               setOpen(!checked);
          }
     }

     const onView = async () => go(`/admin-lottery-numbers?lotteryId=${_id}`);

     if(isDelete) return null;
     return(
          <tr>
               <td>{props.id}</td>
               <td>{ type + " " + time }</td>
               <td>{ endDate }</td>
               <td>{ series }</td>
               <td>{ "₹" + prices }</td>
               <td><Switch checked={isOpen} onChange={(e, checked)=> onChange(checked)}/></td>
               <td><label onClick={onView} style={{cursor: "pointer"}} className="badge badge-gradient-success">View lottery numbers</label></td>
               <td><label onClick={onDelete} style={{cursor: isSending ? "wait" : "pointer"}} className="badge badge-gradient-danger">Delete</label></td>
          </tr>
     );
}

export default LotteryItem;