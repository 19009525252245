import React, {useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {useToasts} from "react-toast-notifications";
import { Delete } from "@material-ui/icons"
import axios from "axios";
import conf from "../../conf";
import './style.css'

const AddLotteryNumber = ()=> {

     const {addToast} = useToasts();
     const showToast = msg => addToast(msg, { appearance: 'error', autoDismiss: true });

     const lotteryId = (new URLSearchParams(useLocation().search)).get("lotteryId");
     const date = new Date();
     const todaydate = date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
     const [data , setData] = useState({
          number: 1,
          count: 30,
          fLotteryNumber: "86 D 125",
          lLotteryNumber: 10,
     });
     const [lotteryNumberList, setLotteryNumberList] = useState([]);

     const onFormChange = (event) => {
          const value = event.target.value;
          const name = event.target.name;
          setData((prvObj)=>{
               return {
                    ...prvObj,
                    [name]: value
               };
          });
     }

     const onSave = async () => {
          
          try {
               await axios({
                    method: 'post',
                    url: conf.serverAddress + "admin/lottery-number",
                    headers: {
                         "Content-Type": "Application/json",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
                    }, 
                    data: {
                         lotteryNumberList,
                         lotteryId
                    }
               });
               window.history.back();
          } 
          catch (err) {
               showToast("Failed to save.")
          }
     }

     const isFromValid = ()=>{
          if(!data.count){
               showToast("Please enter count");
               return false;
          }
          else if(!data.fLotteryNumber){
               showToast("Please enter lottery first number");
               return false;
          }
          else if(!data.lLotteryNumber){
               showToast("Please enter lottery last number");
               return false;
          }
          else if(data.count < 1){
               showToast("Please enter valid count");
               return false;
          }
          return true;
     }

     const onDelete = (index) =>{
          if(index || index == 0) {
               const list = lotteryNumberList.filter((v, i)=> i !== index);
               setLotteryNumberList(list);
          }
          else setLotteryNumberList([]);
     }

     const onNext = () => {
          if(isFromValid()){
               var list = [];
               var number = 0;
               for(let i=0; i<data.count; i++){
                    console.log(number);
                    
                    const f = Number(data.lLotteryNumber) + Number(number);
                    if(data.number == 1){
                         list.push(`${data.fLotteryNumber}${f}`);
                    }
                    else if(data.number == 2){
                         list.push(`${data.fLotteryNumber}${f}-${f + 1}`);
                    }
                    else if(data.number == 5){
                         list.push(`${data.fLotteryNumber}${f}-${f + 4}`);
                    }
                    number+=Number(data.number);
               }
               console.log(list.length);
               setLotteryNumberList(list);
          }
     }

     return(
          <div className="row">
               <div className="col-6 grid-margin">
                    <div className="card">
                         <div className="card-body">
                              <div>
                                   Number
                                   <select value={data.number} name='number' onChange={onFormChange} style={{margin: "10px 73px"}}>   
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="5">5</option>
                                   </select>
                              </div>
                              <div onChange={onFormChange}>
                                   Lottery number
                                   <input type="text" value={data.fLotteryNumber} name="fLotteryNumber" style={{margin: "10px 0 0 18px", border: '1px solid black', width: "100px"}}/>
                                   <input type="number" value={data.lLotteryNumber} name="lLotteryNumber" style={{marginLeft: "5px", border: '1px solid black', width: "80px"}}/>
                              </div>
                              <div onChange={onFormChange}>
                                   Count
                                   <input type="number" value={data.count} name="count" min={1} style={{margin: "10px 90px", border: '1px solid black', width: "80px"}} />
                              </div>
                              
                              <button onClick={onNext} style={{color: "white", background: "blue", border: 'none', padding: "10px 20px", marginTop: "20px",}}>
                                   Next
                              </button>
                              {
                                   lotteryNumberList.length === 0 ?  null :
                                    <button onClick={onSave} style={{marginLeft: "20px", color: "white", background: "green", border: 'none', padding: "10px 20px", marginTop: "20px",}}>
                                        Save
                                   </button>
                              }
                              
                         </div>
                    </div>
               </div>
               <div className="col-6 grid-margin">
                    <div className="card">
                         <div className="card-body" style={{minHeight: "500px", textAlign: 'center'}}>
                              {
                                   lotteryNumberList.length === 0 ? "Empty" :
                                   <div className="lottery_number_box" style={{height: "450px"}}>
                                   <div onClick={()=>onDelete()} style={{cursor: "pointer", width: '100%', display: 'flex', justifyContent: 'end', alignItems: 'center', color: 'red'}}>
                                        Delete All <Delete style={{color: 'red'}} />
                                   </div>
                                       {
                                             lotteryNumberList.map((number, index) => {
                                                  return(
                                                       <div className="lottery_number_item">
                                                            <div style={{display: "inline", cursor: 'pointer'}}>{number}</div>  <label onClick={()=>onDelete(index)}><Delete /></label>
                                                       </div>
                                                  );
                                             })
                                       } 
                                   </div>
                              }
                         </div>
                    </div>
               </div>
          </div>
     );
}

export default AddLotteryNumber;