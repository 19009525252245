import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import conf from '../../conf'

const Login = ()=>{
     
     const [isSending, setIsSending] = useState(false);
     const [isValid, setIsValid] = useState(true);
     const [password, setPassword] = useState('');

     const navigate = useNavigate();

     const admin = document.cookie.split("=")[0];
     if(admin === 'authx'){
          document.location = '/admin-dashboard';
     }

     const onLogin = async ()=>{
          setIsSending(true)
          try {
               const res = await axios.post(conf.serverAddress + "admin/login", {
                    password: password
               });
               let date = new Date(Date.now() + ((((1000 * 60) * 60) * 24) * 30));
               date = date.toUTCString();
               document.cookie = "authx="+ res.data.auth + "; expires=" + date;
               setIsSending(false)
               if(res.data.isAdmin){
                    navigate('/admin-dashboard')
               }
          } catch (error) {
               console.log(error);
               setIsSending(false)
               setIsValid(false)
          }
     }

     return(
          <div className="content-wrapper" style={{position: 'fixed', top: 0, left: 0, height: "100%", display: 'flex', justifyContent: 'center',alignItems: 'center',}}>
               <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    background: 'white',
                    borderRadius: '15px',
                    width: '500px',
                    padding: '80px 0'
               }}>
                    <h2>Admin login</h2>
                    <input value={password} onChange={e=>{setPassword(e.target.value); setIsValid(true)}} type="password" placeholder='admin password...' style={{border: 'none', width: "300px" , background: 'rgba(13, 104, 240, 0.1)', borderRadius: '7px', padding: '10px 20px', fontSize:'16px', marginTop: '25px'}}/>
                    { isValid ? null : <div style={{border: 'none', width: "300px" ,borderRadius: '7px', padding: '0 10px 0 0', fontSize:'14px', marginTop: '5px', color: 'red'}}>
                         *invalid password
                    </div>}
                    <button onClick={onLogin} className="badge badge-gradient-success"  style={{border: 'none', width: "300px" ,borderRadius: '7px', padding: '10px 20px', fontSize:'16px', marginTop: '15px', cursor: isSending ? 'wait' : 'pointer'}}>Login</button>
               </div>
          </div>
     );
}
export default Login;