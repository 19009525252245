import React, { useEffect, useState } from 'react';
import HistoryItem from '../items/HIstoryItem';
import axios from 'axios';
import {ArrowBackIosOutlined, ArrowForwardIosRounded, SearchRounded} from '@material-ui/icons'
import conf from '../../conf'
import OrderItem from '../items/OrderItem';

const Order = () =>{

     const [isNoMoreUsers, setNoMoreUsers] = useState(false);
     const [prvPages, setPrvPages] = useState([]);
     const [nextPages, setNextPages] = useState([]);
     const [totalPages, setTotalPages] = useState(0);
     const [page, setPageIndex] = useState(0);
     const [users, setUsers] = useState([]);
     const [message, setMessage] = useState("Loading...");

     function getAsString(likes){
          return likes;
     }

     const onPageChange = async (index, isNext, searchQuery, isChecked)=>{
          try {
               setMessage('Loading...')
               const params = new URLSearchParams();
               params.append("skip", 20 * index);
               params.append("limit", 20);

               const usersx = await axios.get(conf.serverAddress + "admin/orders", {params: params, headers : {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}});

               if(usersx.data.length != 0){
                    console.log(usersx.data);
                    setUsers(usersx.data);
                    setMessage('')
                    setNoMoreUsers(false)
                    if(isNext){
                         setPageIndex(index);
                    }
                    else{
                         setPageIndex(index);
                    }
               }    
               else{
                    setMessage('Empty!');
               } 
               // if(usersx.data.length < 20){
               //      setNoMoreUsers(true);
               // }

               // pagnation
               // prv
               var prv = [];
               for(let i=0; i<5; i++){
                    const x = (index) - i;
                    if(x >= 1){
                         prv.push(x)
                    }
               }
               prv = prv.reverse()
               setPrvPages(prv);

               // next
               var next = [];
               for(let i=0; i<=5; i++){
                    const x = i+index+2;
                    if(x <= Number(totalPages)+1){
                         next.push(x);
                    }
               }
               setNextPages(next);
          } 
          catch (error) {
               setMessage('Failed to find')
          }
     }

     const buttonStyle = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px',
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)"};

     const buttonStyleDesable = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px', opacity: 0.2,
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)", };

     useEffect( async ()=>{
          onPageChange(0, true);
     }, []);

     return (
          <div className="row">
                    <div className="col-12 grid-margin">
                         <div className="card">
                              <div className="card-body">
                                   {/* <h4 className="card-title">Recent Tickets</h4> */}
                                   <div className="table-responsive">
                                        <table className="table">
                                             <thead>
                                                  <tr>
                                                       <th>No</th>
                                                       <th>User Name</th>
                                                       <th>Prices</th>
                                                       <th>Lottery Number</th>
                                                       <th>Mobile Number</th>
                                                       <th>WhatsApp Number</th>
                                                       <th>Lottery</th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {
                                                       message === "" ? users.map((arr, i) => <OrderItem actionEnable={false} id={((page*20)+i)+1}  ele={arr}/>) : null
                                                  }
                                             </tbody>
                                        </table>
                                        {
                                             message === "" ? null : <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  margin: '100px 0'
                                             }}>{message}</div>
                                        }
                                        <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'end',
                                                  padding: '10px',
                                             }}>
                                                  <div 
                                                       style={{
                                                            borderRadius: '5px',
                                                            // border: '1px solid red'
                                                       }}>
                                                       <ArrowBackIosOutlined className=" bg-gradient-primary" 
                                                            style={page !== 0 ? buttonStyle : buttonStyleDesable}
                                                            onClick={()=>{
                                                                 if(page > 0){
                                                                      onPageChange(page - 1, false)
                                                                 }
                                                            }}
                                                       />
                                                       {
                                                            prvPages.map(val =>{
                                                                 return(
                                                                      <span style={{margin: "0 15px", cursor: 'pointer' }} onClick={()=>{onPageChange(val-1, false)}}>{val}</span>
                                                                 );
                                                            })
                                                       }
                                                       <span style={{margin: "0 15px", color: "rgb(69,162,235)", cursor: 'pointer'}}>{page + 1}</span>
                                                       {
                                                            nextPages.map(val =>{
                                                                 return(
                                                                      <span style={{margin: "0 15px", cursor: 'pointer' }} onClick={()=>{onPageChange(val-1, true)}}>{val}</span>
                                                                 );
                                                            })
                                                       }
                                                       
                                                       <ArrowForwardIosRounded className=" bg-gradient-primary"
                                                            style={isNoMoreUsers ? buttonStyleDesable : buttonStyle}
                                                            onClick={()=>{
                                                                 if(!isNoMoreUsers){
                                                                      onPageChange(page + 1, true)
                                                                 }
                                                            }}
                                                            />
                                                  </div>
                                             </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
     );
}

export default Order;