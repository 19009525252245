import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UserDataItem from '../items/UserDataItem'
import {ArrowBackIosOutlined, ArrowForwardIosRounded, SearchRounded} from '@material-ui/icons'
import conf from '../../conf'
import "./style.css"

const Deshboard = () => {
     const [isNoMoreUsers, setNoMoreUsers] = useState(false);
     const [prvPages, setPrvPages] = useState([]);
     const [nextPages, setNextPages] = useState([]);
     const [totalPages, setTotalPages] = useState(0);
     const [page, setPageIndex] = useState(0);
     const [users, setUsers] = useState([]);
     const [message, setMessage] = useState("Loading...");
     const [search, setSearch] = useState("");
     const [deshInfo, setInfo] = useState({
          users: "...",
     });

     function getAsString(likes){
          return likes;
     }

     const onPageChange = async (index, isNext, searchQuery, isChecked)=>{
          try {
               setMessage('Loading...')
               const params = new URLSearchParams();
               params.append("skip", 20 * index);
               params.append("limit", 20);

               if(searchQuery){
                    params.append("searchQuery", searchQuery);
               }
               if(isChecked){
                    params.append("isChecked", isChecked);
               }

               const usersx = await axios.get(conf.serverAddress + "admin/users", {params: params, headers : {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}});
               if(usersx.data.length != 0){
                    setUsers(usersx.data);
                    setMessage('')
                    setNoMoreUsers(false)
                    if(isNext){
                         setPageIndex(index);
                    }
                    else{
                         setPageIndex(index);
                    }
               }    
               else{
                    setMessage('Empty!');
               } 
               if(usersx.data.length < 20){
                    setNoMoreUsers(true);
               }

               // pagnation
               // prv
               var prv = [];
               for(let i=0; i<5; i++){
                    const x = (index) - i;
                    if(x >= 1){
                         prv.push(x)
                    }
               }
               prv = prv.reverse()
               setPrvPages(prv);

               // next
               var next = [];
               for(let i=0; i<=5; i++){
                    const x = i+index+2;
                    if(x <= Number(totalPages)+1){
                         next.push(x);
                    }
               }
               setNextPages(next);
          } 
          catch (error) {
               setMessage('Failed to find')
          }
     }

     const onSearch = (query)=>{
          onPageChange(0, true, query);
     }

     const getHomeData = async (targetDays) =>{
          try {
               const params = new URLSearchParams();
               params.append("days", targetDays);
               const info = await axios.get(conf.serverAddress + "admin/home-data", {
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`},
                    params: targetDays !== 0 ? params : null
               });
               setInfo({
                    users: getAsString(info.data.users),
               });
               const totalUsers = Number(info.data.users).toFixed(0);
               setTotalPages(totalUsers < 20 ? 1 : ((totalUsers / 20).toFixed(0)));
          }
          catch (error) {
               setInfo({
                    users: "Failed",
               });
          }
     }

     const buttonStyle = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px',
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)"};

     const buttonStyleDesable = {cursor: 'pointer' ,color: 'white', height: '35px', width: '35px', opacity: 0.2,
     borderRadius: "5px", padding: '10px 10px', boxShadow: "2px 2px 15px rgba(177, 21, 224, 0.274)", };

     useEffect( async ()=>{ 
          // get and set info
          await getHomeData(0);
          
          onPageChange(0, true);
     }, []);

     return (
          <>
               <div className="page-header">
                    <h3 className="page-title">
                         <span className="page-title-icon bg-gradient-primary text-white mr-2">
                              <i className="mdi mdi-home"></i>
                         </span>
                         Dashboard
                    </h3>
                    {/* <select onChange={(e)=>getHomeData(Number(e.target.value))} className='p-select' style={{border: "none", borderRadius: '5px'}}> 
                         <option value="0">Life time</option>
                         <option value="1">24 hours</option>
                         <option value="7">7 days</option>
                         <option value="30">30 days</option>
                         <option value="180">6 months</option>
                         <option value="365">1 year</option>
                    </select> */}
               </div>
               <div className="row">
                    <div className="col-md-4 stretch-card grid-margin">
                         <div className="card bg-gradient-danger card-img-holder text-white">
                              <div className="card-body">
                                   <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image"/>
                                   <h4 className="font-weight-normal mb-3">Users
                                        <i className="mdi mdi-account mdi-24px float-right"></i>
                                   </h4>
                                   <h2 className="mb-5">{deshInfo.users}</h2>
                                   <h6 className="card-text"></h6>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="page-header" style={{display: 'flex', justifyContent: "space-around"}}>
                    <h3 className="page-title">
                         Users
                    </h3>
                    <div style={{padding: "10px 20px",borderRadius: "5px", background: "#FFF", display:"flex", alignItems: "center"}} >
                         <input className='input_n' value={search} 
                              onChange={(e)=>setSearch(e.target.value)} 
                              placeholder="Search by number"
                              style={{border:'none'}}
                              type="number"
                              />
                         <SearchRounded style={{width: "20px"}} onClick={()=>{onSearch(search)}} />
                    </div>
               </div>

               <div className="row">
                    <div className="col-12 grid-margin">
                         <div className="card">
                              <div className="card-body">
                                   {/* <h4 className="card-title">Recent Tickets</h4> */}
                                   <div className="table-responsive">
                                        <table className="table">
                                             <thead>
                                                  <tr>
                                                       <th>No</th>
                                                       <th>User</th>
                                                       <th>Password</th>
                                                       <th>Balance</th>
                                                       <th>Upi id</th>
                                                       <th>Mobile Number</th>
                                                       <th>WhatsApp Number</th>
                                                       <th>Last recharge</th>
                                                       <th>Email</th>
                                                       <th></th>
                                                       <th></th>
                                                  </tr>
                                             </thead>
                                             <tbody>
                                                  {
                                                       message === "" ? users.map((arr, i) => <UserDataItem actionEnable={false} id={((page*20)+i)+1}  ele={arr}/>) : null
                                                  }
                                             </tbody>
                                        </table>
                                        {
                                             message === "" ? null : <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'center',
                                                  margin: '100px 0'
                                             }}>{message}</div>
                                        }
                                        <div style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent: 'end',
                                                  padding: '10px',
                                             }}>
                                                  <div 
                                                       style={{
                                                            borderRadius: '5px',
                                                            // border: '1px solid red'
                                                       }}>
                                                       <ArrowBackIosOutlined className=" bg-gradient-primary" 
                                                            style={page !== 0 ? buttonStyle : buttonStyleDesable}
                                                            onClick={()=>{
                                                                 if(page > 0){
                                                                      onPageChange(page - 1, false)
                                                                 }
                                                            }}
                                                       />
                                                       {
                                                            prvPages.map(val =>{
                                                                 return(
                                                                      <span style={{margin: "0 15px", cursor: 'pointer' }} onClick={()=>{onPageChange(val-1, false)}}>{val}</span>
                                                                 );
                                                            })
                                                       }
                                                       <span style={{margin: "0 15px", color: "rgb(69,162,235)", cursor: 'pointer'}}>{page + 1}</span>
                                                       {
                                                            nextPages.map(val =>{
                                                                 return(
                                                                      <span style={{margin: "0 15px", cursor: 'pointer' }} onClick={()=>{onPageChange(val-1, true)}}>{val}</span>
                                                                 );
                                                            })
                                                       }
                                                       
                                                       <ArrowForwardIosRounded className=" bg-gradient-primary"
                                                            style={isNoMoreUsers ? buttonStyleDesable : buttonStyle}
                                                            onClick={()=>{
                                                                 if(!isNoMoreUsers){
                                                                      onPageChange(page + 1, true)
                                                                 }
                                                            }}
                                                            />
                                                  </div>
                                             </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}

export default Deshboard;
