import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import './style.css'
import axios from "axios";
import conf from '../../conf'
import { Switch } from "@material-ui/core"

const LotteryNumberItem = (props)=>{

     const go = useNavigate();
     const { lotteryNumber, soldout, lotteryId, _id /* _id : lottery number id */ } = props.ele;
     const [isDelete, setDelete] = useState(false);
     const [isSending, setSending] = useState(false);
     const [isSoldout, setSoldout] = useState(soldout);

     const onDelete = async () =>{
          setSending(true);
          try{
               await axios.delete(conf.serverAddress + "admin/lottery-number", {
                    data: {id: _id},
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setDelete(true);
          }
          catch(e){
               setSending(false);
          }
     }

     const onSoldoutChange = async (checked) => {
          try {
               await axios({
                    method: 'patch',
                    url: conf.serverAddress + "admin/lottery-number",
                    headers: {
                         "Content-Type": "Application/json",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
                    }, 
                    data: {
                         id: _id,
                         isSoldout: checked,
                    }
               });
          } 
          catch (err) {
               console.log(err);
               setSoldout(!checked);
          }
     }

     if(isDelete) return null;
     return(
          <tr>
               <td>{props.id}</td>
               <td>{lotteryNumber}</td>
               <td><Switch checked={isSoldout} onChange={(e, checked)=>{
                    setSoldout(checked);
                    onSoldoutChange(checked);
               }} /></td>
               <td><label onClick={onDelete} style={{cursor: isSending ? "wait" : "pointer"}} className="badge badge-gradient-danger">Delete</label></td>
          </tr>
     );
}

export default LotteryNumberItem;