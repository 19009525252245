import React from "react";
import './style.css'

const HistoryItem = (props)=>{

     const { title, prices , lotteryNumber, userName, mobileNumber, whatsappNumber, date } = props.ele;
     const actionEnable = props.actionEnable;
     
     return(
          <tr>
               <td>{props.id}</td>
               <td>{ userName }</td>
               <td>{ "₹" + prices }</td>
               <td>
                    <label className="badge badge-gradient-success">{lotteryNumber}</label>
               </td>
               <td>{mobileNumber}</td>
               <td>{whatsappNumber}</td>
               <td>{title}</td>
               <td>{new Date(date).toDateString()}</td>
          </tr>
     );
}

export default HistoryItem;