import React, { useState } from "react";
import './style.css'
import axios from "axios";
import conf from '../../conf'

const WinnerItem = (props)=>{

     const { priceType, winnerName , lotteryNumber, series, endDate, type, lotteryPrices, winningPrices, _id } = props.ele;
     const [isDelete, setDelete] = useState(false);
     const [isSending, setSending] = useState(false);

     const onDelete = async () =>{
          setSending(true);
          try{
               await axios.delete(conf.serverAddress + "admin/winner", {
                    data: {id: _id},
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setDelete(true);
          }
          catch(e){
               setSending(false);
          }
     }

     if(isDelete) return null;
     return(
          <tr>
               <td>{props.id}</td>
               <td>{ winnerName }</td>
               <td>
                    <label className="badge badge-gradient-success">{lotteryNumber}</label>
               </td>
               <td>{ series }</td>
               <td>{ priceType }</td>
               <td>{ "₹" + lotteryPrices }</td>
               <td>{ "₹" + winningPrices }</td>
               <td>{ type + " " + endDate }</td>
               <td><label onClick={onDelete} style={{cursor: isSending ? "wait" : "pointer"}} className="badge badge-gradient-danger">Delete</label></td>
          </tr>
     );
}

export default WinnerItem;